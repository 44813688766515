import { graphql } from 'gatsby';
import React, { useState } from 'react';

import Intro from '../components/site/Intro';
import Layout from '../components/site/Layout';
import SEO from '../components/site/SEO';

export default function IndexPage() {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Layout isModalOpen={isModalOpen} onModalClose={() => setModalOpen(false)} onModalOpen={() => setModalOpen(true)}>
      <SEO title="Polkadot Pioneers Prize" bodyClassName={isModalOpen ? 'modal-open' : ''} />
      <Intro />
      <div className="content" id="content">
        <div className="content__body">
          <div className="content__aside">
            <h2>Incentivizing Innovation in the Polkadot Ecosystem</h2>
            <a
              className="button"
              href="https://github.com/pioneersprize/Polkadot-Pioneers-Prize/tree/main"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
          <div className="content__main">
            <h3>Introducing the Polkadot Pioneers Prize</h3>
            <p>
              Got what it takes to be a Polkadot Pioneer? The Polkadot Pioneers Prize encourages talented people to
              solve some of the biggest challenges facing Polkadot and Web3 and win up to 993,286.08 DOT in prizes. In
              this historic first, prizes will be funded in a completely decentralized way via Polkadot’s on-chain
              Treasury.
            </p>
            <h3>Unlocking the power of Polkadot’s Treasury</h3>
            <p>
              The Polkadot Treasury is managed by the network’s token holders via Polkadot’s on-chain governance system.
              Currently containing more than 20 million DOT tokens, the Treasury is designed to fund projects that
              benefit the Polkadot ecosystem. The Polkadot Treasury represents a completely new paradigm, where
              decentralized networks have the financial agency previously limited to centralized organizations.
              <br />
              <br />
              If you are interested in viewing the on-chain bounty proposal that brought the Pioneers Prize to life, you
              can{' '}
              <a
                href="https://polkadot.polkassembly.io/bounty/10"
                target="_blank"
                rel="noreferrer"
                className="text-blended"
              >
                view the proposal here
              </a>
              .
            </p>

            <h3>How to get involved</h3>
            <div className="grid">
              <div className="grid__item">
                <div className="step">1</div>
                <h4>Check the Bounty Categories</h4>
                <p>
                  Visit the{' '}
                  <a
                    className="text-blended"
                    href="https://www.notion.so/Polkadot-Pioneers-Prize-Pool-7aa03a1be489434db0834f084ddffc2f"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pioneers Prize Notion page
                  </a>{' '}
                  and search through the available Bounties.
                </p>
              </div>
              <div className="grid__item">
                <div className="step">2</div>
                <h4>Draft your proposal</h4>
                <p>
                  Your proposal should address a problem, outline a goal, give a detailed account of how you will reach
                  that goal, and include any ongoing maintenance needs. As much as possible, you should itemize the
                  tasks to be completed so fees can be evaluated and milestones can be followed.
                  <br />
                  <a
                    className="text-blended"
                    href="https://docs.google.com/document/d/1O_84mXYFERCavmnJyxbIPKFkG0bVBySRjCVy-d-VKcc/edit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View the proposal template
                  </a>
                </p>
              </div>
              <div className="grid__item">
                <div className="step">3</div>
                <h4>Submit your proposal</h4>
                <p>
                  Follow the{' '}
                  <a
                    className="text-blended"
                    href="https://github.com/pioneersprize/Polkadot-Pioneers-Prize/tree/main"
                    target="_blank"
                    rel="noreferrer"
                  >
                    submission steps
                  </a>{' '}
                  and register your entry to the Bounty.
                </p>
              </div>
              <div className="grid__item">
                <div className="step">4</div>
                <h4>Pitch Call</h4>
                <p>
                  Once your proposal has been through an initial screening and feedback process, the team will be
                  invited to a pitch call with the Curator.
                </p>
              </div>
              <div className="grid__item">
                <div className="step">5</div>
                <h4>Curator Selection</h4>
                <p>
                  If approved, the requested funds are earmarked/locked in the Bounty pool, and the Child Bounty is
                  ready to be assigned to the Curator.
                </p>
              </div>
              <div className="grid__item">
                <div className="step">6</div>
                <h4>Child Bounty Creation & Claiming</h4>
                <p>
                  Once the payment structure has been agreed and the Curator has been assigned to the newly created
                  Child Bounty, the recipient can claim the Child Bounty.
                  <br />
                  <a
                    className="text-blended"
                    href="https://paritytech.github.io/substrate/master/pallet_child_bounties/pallet/struct.Pallet.html#method.claim_child_bounty"
                  >
                    More information
                  </a>
                </p>
              </div>
              <div className="grid__item">
                <div className="step">7</div>
                <h4>Report back to the Community</h4>
                <p>
                  Be sure to report back to the community in discussion forums on the progress your project makes,
                  showing relevant deliverables, results, links to work done, and any success metrics if relevant. This
                  is crucial for ensuring accountability and community alignment, particularly if you want to continue
                  working with Treasury funds in the future.
                </p>
              </div>
            </div>

            <h3>Bounty Categories</h3>
            <p>
              A full list of Bounty categories can be found on the{' '}
              <a
                href="https://www.notion.so/Polkadot-Pioneers-Prize-Pool-7aa03a1be489434db0834f084ddffc2f"
                target="_blank"
                rel="noreferrer"
                className="text-blended"
              >
                Pioneers Prize Notion page
              </a>
              .
            </p>

            <h3>Previous Recipients</h3>
            <p className="mb-10">
              A full rundown of the innovative projects which have been funded from this Bounty can be found below:
            </p>

            <h3 className="mt-6">Zprize</h3>
            <h4>About Zprize</h4>
            <p>
              Zero-knowledge cryptography is a groundbreaking new technology enabling privacy, interoperability, and
              scalability for Web 3.0 protocols and applications. Like the DARPA Grand Challenge, select teams will
              compete for monetary prizes spanning a range of categories. Over $7M in prize money has been committed,
              along with token grants from sponsoring projects.
            </p>
            <p>
              All winning submissions will become open-source libraries for the benefit of all. We hope this foundation
              can support the next generation of decentralized protocols and applications to enable secure,
              interoperable, and scalable applications for the next-generation web.
            </p>
            <h4 className="mt-10">Pioneers Prize Open Division Challenge</h4>
            <p>
              <a
                href="https://www.zprize.io/prizes/accelerating-elliptic-curve-operations-and-finite-field-arithmetic-wasm"
                target="_black"
                rel="noreferrer"
                className="text-blended"
              >
                Accelerating elliptic curve operations and finite field arithmetic (WASM)
              </a>{' '}
              focuses on maximizing throughput/minimizing latency of operations on client-type devices and
              blockchain-based VMs, specifically the WebAssembly (WASM) runtime.
            </p>

            <h3>Zprize Winners</h3>
            <div className="grid grid--prizes">
              <div>
                <div className="step step--centered">1</div>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="prize-icon text-blended"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                  />
                </svg> */}

                <h4>Yrrid Software</h4>
                <p>
                  Amount: 15,372.50 DOT <br />
                  <a
                    href="https://github.com/z-prize/2022-entries/tree/main/open-division/prize4-msm-wasm/yrrid"
                    target="_black"
                    rel="noreferrer"
                    className="text-blended"
                  >
                    Github repo submission
                  </a>
                </p>
              </div>
              <div>
                <div className="step step--centered">2</div>
                <h4>mitschabaude</h4>
                <p>
                  Amount: 5,912.50 DOT <br />
                  <a
                    href="https://github.com/z-prize/2022-entries/tree/main/open-division/prize4-msm-wasm/mitschabaude"
                    target="_black"
                    rel="noreferrer"
                    className="text-blended"
                  >
                    Github repo submission
                  </a>
                </p>
              </div>
              <div>
                <div className="step step--centered">3</div>
                <h4>Manta Network</h4>
                <p>
                  Amount: 2,365 DOT <br />
                  <a
                    href="https://github.com/z-prize/2022-entries/tree/main/open-division/prize4-msm-wasm/Manta-Network"
                    target="_black"
                    rel="noreferrer"
                    className="text-blended"
                  >
                    Github repo submission
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
