/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import IntroVideo from '../../images/beams.mp4';

const Intro = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  useEffect(() => {
    function resizeCanvas() {
      canvas.height = window.innerHeight + contentWrapper.scrollHeight + footerWrapper.scrollHeight;
      canvas.classList.add('intro-visible');
      canvas.width = document.body.clientWidth;
    }

    function randomFromInterval(from, to) {
      return Math.random() * (to - from + 1) + from;
    }

    function updateCircles() {
      for (let i = 0; i < controls.circleCount; i++) {
        circles[i].update();
      }
    }

    function drawCircles() {
      for (let i = 0; i < controls.circleCount; i++) {
        circles[i].draw();
      }
    }

    function play() {
      window.requestAnimationFrame(play);
      clear();
      updateCircles();
      drawCircles();
    }

    function clear() {
      ctx.globalCompositeOperation = 'destination-out';
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'source-over';
    }

    window.onresize = function () {
      resizeCanvas();
    };

    const contentWrapper = document.getElementById('content');
    const footerWrapper = document.getElementById('footer');
    const canvasWrapper = document.getElementById('intro__canvas-wrapper');
    const canvas = document.getElementById('intro__canvas');
    const ctx = canvas.getContext('2d');
    resizeCanvas();

    let Settings = function () {
      this.index = 0;
      this.speed = 0.25;
      this.circleCount = 6;
      this.radius = canvas.width / 8;
      // this.circleCount = 20;
      // this.radius = canvas.width / 20;
      this.showInfo = true;
    };

    const controls = new Settings();

    let Circle = function () {
      this.index = controls.index++;
      this.radius = controls.radius;
      this.y = randomFromInterval(controls.radius, window.innerHeight - controls.radius);
      this.x = randomFromInterval(controls.radius, window.innerWidth - controls.radius);
      this.vx = randomFromInterval(-1, 1);
      this.vy = randomFromInterval(-1, 1);
    };

    Circle.prototype.update = function () {
      this.x += this.vx * controls.speed;
      this.y += this.vy * controls.speed;
      if (this.x > canvas.width - controls.radius) {
        this.x = canvas.width - controls.radius;
        this.vx *= -1;
      } else if (this.x < controls.radius) {
        this.x = controls.radius;
        this.vx *= -1;
      } else if (this.y > canvas.height - controls.radius) {
        this.y = canvas.height - controls.radius;
        this.vy *= -1;
      } else if (this.y < controls.radius) {
        this.y = controls.radius;
        this.vy *= -1;
      }
    };

    Circle.prototype.draw = function () {
      ctx.beginPath();
      ctx.arc(this.x, this.y, controls.radius, 0, Math.PI * 2);
      ctx.fillStyle = 'rgba(230,0,122,1)';
      ctx.fill();
    };

    //initialise
    let circles = [];
    for (let i = 0; i < controls.circleCount; i++) {
      circles.push(new Circle());
    }

    //start animation
    play();

    // handle mousemove
    function handleMouseMove(e) {
      let _w = window.innerWidth / 2;
      let _h = window.innerHeight / 2;
      let _mouseX = e.clientX;
      let _mouseY = e.clientY;
      let _depth = `translate(${(_mouseX - _w) * 0.005}vw, ${(_mouseY - _h) * 0.005}vh)`;
      let x = `${_depth}`;
      canvasWrapper.style.transform = x;
    }
    document.addEventListener('mousemove', handleMouseMove);
  }, []);

  function scrollToContent(event) {
    event.preventDefault();
    let target = event.target.hash;
    target = target.substring(1);
    const element = document.getElementById(target);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="intro" id="intro">
      <video id="intro__video" className="intro__video" loop muted autoPlay data-keepplaying>
        <source type="video/mp4" src={IntroVideo} />
      </video>
      <div id="intro__canvas-wrapper" className="intro__video">
        <canvas id="intro__canvas" className="intro__canvas" />
      </div>
      <div className="intro__content">
        <h1>Pioneers Prize</h1>
        <h2>Solve big challenges for the biggest bounties in blockchain.</h2>
      </div>
      <a
        href="#content"
        className={scrollTop > 400 ? 'intro__scroll-down intro__scroll-down--hidden' : 'intro__scroll-down'}
        onClick={scrollToContent}
      ></a>
    </div>
  );
};

export default Intro;
